exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-photography-tsx": () => import("./../../../src/pages/photography.tsx" /* webpackChunkName: "component---src-pages-photography-tsx" */),
  "component---src-pages-style-guide-tsx": () => import("./../../../src/pages/style-guide.tsx" /* webpackChunkName: "component---src-pages-style-guide-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-00-website-redesign-rebuild-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/00-website-redesign-rebuild/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-00-website-redesign-rebuild-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-01-design-style-guide-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/01-design-style-guide/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-01-design-style-guide-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-02-headless-vs-git-backed-cms-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/02-headless-vs-git-backed-cms/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-02-headless-vs-git-backed-cms-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-03-aeropress-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/03-aeropress/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-03-aeropress-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-04-creating-a-component-library-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/04-creating-a-component-library/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-04-creating-a-component-library-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-05-lagom-ui-logo-design-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/05-lagom-ui-logo-design/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-05-lagom-ui-logo-design-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-06-design-systems-thinking-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/06-design-systems-thinking/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-06-design-systems-thinking-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-07-forming-a-conference-talk-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/07-forming-a-conference-talk/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-07-forming-a-conference-talk-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-08-component-design-in-figma-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/08-component-design-in-figma/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-08-component-design-in-figma-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-09-design-tokens-to-dark-mode-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/09-design-tokens-to-dark-mode/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-09-design-tokens-to-dark-mode-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-10-breaking-through-beyond-the-job-title-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/10-breaking-through-beyond-the-job-title/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-10-breaking-through-beyond-the-job-title-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-11-design-tokens-a-presentation-abstract-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/11-design-tokens-a-presentation-abstract/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-11-design-tokens-a-presentation-abstract-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-content-posts-12-design-tokens-creating-a-lightning-talk-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=/opt/build/repo/content/posts/12-design-tokens-creating-a-lightning-talk/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-content-posts-12-design-tokens-creating-a-lightning-talk-index-mdx" */)
}

